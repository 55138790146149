angular.module('MonstaFTP').filter('item_permission_description', ['permissionsFactory', function (permissionsFactory) {
    return function (item) {
        var description = item.isDirectory ? 'd' : '-';
        var permissionObject = permissionsFactory.numericToObject(item.numericPermissions);
        description += permissionObject.ownerRead ? 'r' : '-';
        description += permissionObject.ownerWrite ? 'w' : '-';
        description += permissionObject.ownerExecute ? 'x' : '-';

        description += permissionObject.groupRead ? 'r' : '-';
        description += permissionObject.groupWrite ? 'w' : '-';
        description += permissionObject.groupExecute ? 'x' : '-';

        description += permissionObject.otherRead ? 'r' : '-';
        description += permissionObject.otherWrite ? 'w' : '-';
        description += permissionObject.otherExecute ? 'x' : '-';

        return description;
    };
}]);
