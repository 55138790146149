(function () {
    angular.module('MonstaFTP').controller('SlidebarController', SlidebarController);

    SlidebarController.$inject = ['authenticationFactory', '$rootScope', 'jQuery', '$translate'];

    function SlidebarController(authenticationFactory, $rootScope, jQuery, $translate) {
        var vm = this;

        jQuery('#slidebar').ready(function () {
            jQuery.slidebars();
        });

        vm.confirmLogout = confirmLogout;

        function confirmLogout() {
            jQuery.slidebars.close('#slidebar');
            authenticationFactory.logout();
            $rootScope.$broadcast('logout');
        }

        vm.initiateLogout = initiateLogout;
        vm.showAddonsModal = showAddonsModal;
        vm.showSettingsModal = showSettingsModal;
        vm.showLoginPanel = showLoginPanel;

        function showModalConfirm(translatedMessage) {
            $rootScope.$broadcast('modal-confirm:show', translatedMessage, vm.confirmLogout);
        }

        function initiateLogout() {
            $translate('LOGOUT_CONFIRM_MESSAGE').then(showModalConfirm, showModalConfirm);
        }

        function showAddonsModal() {
            $rootScope.$broadcast('modal-addons:show');
        }

        function showSettingsModal() {
            $rootScope.$broadcast('modal-settings:show');
        }

        function showLoginPanel() {
            $rootScope.$broadcast('modal-login:show');
            jQuery.slidebars.close('#slidebar');
        }
    }
}());

