(function () {
    angular.module('MonstaFTP').factory('codeMirrorFactory', codeMirrorFactory);

    codeMirrorFactory.$inject = ['$window'];

    function codeMirrorFactory($window) {
        var _jQuery = $window.jQuery; // can't inject factory into factory it seems
        return {
            jQuery: _jQuery, // for reference in tests & mocking
            loadedModes: [],
            convertFilenameToMode: function (fileName) {
                var fileExtension = extractFileExtension(fileName);

                var modeLookup = {
                    htm: 'htmlmixed',
                    html: 'htmlmixed',
                    php: 'php',
                    asp: 'htmlembedded',
                    aspx: 'htmlembedded',
                    js: 'javascript',
                    css: 'css',
                    xhtml: 'htmlmixed',
                    cfm: 'htmlembedded',
                    pl: 'perl',
                    py: 'python',
                    c: 'clike',
                    cpp: 'clike',
                    rb: 'ruby',
                    java: 'java',
                    xml: 'xml'
                };

                return modeLookup.hasOwnProperty(fileExtension) ? modeLookup[fileExtension] : null;
            },
            getModeDependencies: function (modeName) {
                var dependencyLookup = {
                    'htmlmixed': ['xml', 'javascript', 'css'],
                    'php': ['xml', 'javascript', 'css', 'htmlmixed', 'clike']
                    // todo: make these work recursively instead of coding all deps in
                };

                return dependencyLookup.hasOwnProperty(modeName) ? dependencyLookup[modeName] : null;
            },
            generateModePath: function (modeName) {
                return CM_MODE_BASE + modeName + "/" + modeName + '.js';
            }, setupCodeMirror: function (modeName, editorElement, postSetupCallback) {
                var cm = CodeMirror.fromTextArea(editorElement, {
                    value: editorElement.value,
                    mode: modeName,
                    lineNumbers: true,
                    lineWrapping: true
                });

                postSetupCallback(cm);
            }, postScriptLoad: function (modeName, editorElement, postSetupCallback) {
                if (this.loadedModes.indexOf(modeName) == -1)
                    this.loadedModes.push(modeName);

                this.setupCodeMirror(modeName, editorElement, postSetupCallback);
            }, loadModeAfterDependencies: function (modeName, editorElement, postSetupCallback) {
                if (this.loadedModes.indexOf(modeName) != -1) {
                    this.setupCodeMirror(modeName, editorElement, postSetupCallback);
                    return;
                }
                var _this = this;
                this.jQuery.getScript(this.generateModePath(modeName), function () {
                    _this.postScriptLoad.call(_this, modeName, editorElement, postSetupCallback);
                });
            }, initiateCodeMirror: function (modeName, editorElement, postSetupCallback) {
                var dependencies = this.getModeDependencies(modeName);

                var neededDependencies = [];

                if (dependencies != null) {
                    for (var i = 0; i < dependencies.length; ++i)
                        if (this.loadedModes.indexOf(dependencies[i]) == -1)
                            neededDependencies.push(dependencies[i]);
                }

                if (neededDependencies.length == 0)
                    this.loadModeAfterDependencies(modeName, editorElement, postSetupCallback);
                else {
                    var dependencyName = neededDependencies[0], _this = this;

                    this.jQuery.getScript(this.generateModePath(dependencyName), function () {
                        _this.loadedModes.push(dependencyName);
                        _this.initiateCodeMirror.call(_this, modeName, editorElement, postSetupCallback);
                    });
                }
            }
        };
    }
}());

