(function(){
    angular.module('MonstaFTP').factory('configurationFactory', configurationFactory);

    configurationFactory.$inject = ['connectionFactory', '$q', '$rootScope'];

    function configurationFactory(connectionFactory, $q, $rootScope) {
        var factory = {
            getSystemConfiguration: getSystemConfiguration,
            saveApplicationSettings: saveApplicationSettings,
            setApplicationSetting: setApplicationSetting,
            getApplicationSetting: getApplicationSetting
        }, promise = null, lastRequestFailed = false, config = null;

        function getSystemConfiguration() {
            if(promise === null || lastRequestFailed)
                promise = connectionFactory.getSystemVars().then(requestSuccess, requestFailure);

            return promise;
        }

        function requestSuccess(response) {
            config = response.data.data;
            return config;
        }

        function requestFailure(response) {
            lastRequestFailed = true;
            return $q.reject(response);
        }

        function saveApplicationSettings() {
            return connectionFactory.setApplicationSettings(config.applicationSettings);
        }

        function setApplicationSetting(key, value) {
            if (config == null)
                return;

            if (config.applicationSettings == undefined)
                config.applicationSettings = {};

            var keyChanged = config.applicationSettings[key] != value;

            config.applicationSettings[key] = value;

            if(keyChanged)
                $rootScope.$broadcast('configuration:key-changed', key, value);
        }

        function getApplicationSetting(key) {
            if(config == null)
                return null;

            return config.applicationSettings[key];
        }

        return factory;
    }
}());