var HISTORY_MAX = 5;
var TRANSFER_RATE_UPDATE_INTERVAL = 200;
var TRANSFER_RATE_SAMPLES_MAX = 10;
var AUTOSAVE_DELAY_MS = 3000;
var MAX_CONCURRENT_UPLOADS = 1;
var MAX_UPLOAD_BYTES = -1;
var UPLOAD_ACTION = 'uploadFileToNewDirectory';
var UPLOAD_ARCHIVE_ACTION = 'uploadArchive';
var API_PATH = 'application/api/api.php';
var DOWNLOAD_PATH = 'application/api/download.php';
var UPLOAD_PATH = 'application/api/upload.php';
var CM_MODE_BASE = "//cdnjs.cloudflare.com/ajax/libs/codemirror/5.17.0/mode/";
var EXTRACT_UPLOAD = false;
var DEBUG = false;

var g_ConnectionDefaults = {
    ftp: {
        port: 21
    },
    sftp: {
        port: 22
    }
};

angular.module('MonstaFTP', ['pascalprecht.translate']);

angular.module('MonstaFTP').config(['$httpProvider', function ($httpProvider) {
    'use strict';
    var activeRequestCount = 0;

    $httpProvider.interceptors.push(['$rootScope', '$q', function ($rootScope, $q) {
        var handleActiveRequestChange = function (delta) {
            activeRequestCount += delta;
            $rootScope.$broadcast('request-count-change', activeRequestCount);
        };

        return {
            'request': function (config) {
                handleActiveRequestChange(1);
                return config;
            },
            'responseError': function (rejection) {
                handleActiveRequestChange(-1);
                return $q.reject(rejection);
            },
            'response': function (response) {
                handleActiveRequestChange(-1);
                return response;
            }
        };
    }]);
}]);

angular.module('MonstaFTP').config(['$translateProvider', function ($translateProvider) {
    'use strict';
    $translateProvider.useSanitizeValueStrategy('escape');
    if (!window.underTest) { // TODO this is a bit hack
        $translateProvider.useStaticFilesLoader({
            prefix: 'application/languages/',
            suffix: '.json'
        });
    }

    $translateProvider.useMessageFormatInterpolation();
    $translateProvider.fallbackLanguage('en_us');

    if(window.localStorage) {
        try {
            var storedLanguage = localStorage.getItem('monsta-setting-language');
            if(storedLanguage)
                g_defaultLanguage = JSON.parse(storedLanguage);
        } catch (e) {

        }
    }

    $translateProvider.preferredLanguage(g_defaultLanguage);
}]);