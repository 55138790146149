(function () {
    angular.module('MonstaFTP').controller('ModalChoiceController', ModalChoiceController);

    ModalChoiceController.$inject = ["$scope", "jQuery", '$timeout'];

    function ModalChoiceController($scope, jQuery, $timeout) {
        var vm = this, modalId = "#modal-choice";
        vm.callbacks = [];
        vm.title = null;
        vm.message = null;
        vm.show = show;
        vm.handleCallback = handleCallback;

        $scope.$on('modal-choice:show', function (ev, title, message, callbacks) {
            vm.title = title;
            vm.message = message;
            vm.callbacks = callbacks;

            $timeout(function () {
                $scope.$apply(function () {
                    vm.show();
                });
            });
        });

        function show() {
            jQuery(modalId).modal('show');
        }

        function hide() {
            jQuery(modalId).modal('hide');
        }

        function handleCallback(callbackIndex) {
            vm.callbacks[callbackIndex][1]();
            hide();
        }
    }
}());