(function () {
    angular.module('MonstaFTP').factory('localConfigurationFactory', localConfigurationFactory);

    localConfigurationFactory.$inject = ['configurationFactory', '$rootScope'];

    function localConfigurationFactory(configurationFactory, $rootScope) {
        var factory = {
            getApplicationSettings: getApplicationSettings,
            getConfigurationItem: getConfigurationItem,
            setConfigurationItem: setConfigurationItem
        };

        var isLocalStorageSupported = null, inMemoryStorage = {}, applicationSettings = {};

        function localStorageSupported() {
            if (isLocalStorageSupported == null) {
                var testKey = 'test', storage = window.localStorage;
                try {
                    storage.setItem(testKey, '1');
                    storage.removeItem(testKey);
                    isLocalStorageSupported = true;
                } catch (error) {
                    isLocalStorageSupported = false;
                }
            }
            return isLocalStorageSupported;
        }

        function getItemFromLocalStorage(key) {
            var rawItem = localStorage.getItem(key);

            if (typeof (rawItem) == "string")
                return JSON.parse(rawItem);

            return rawItem;
        }

        function storeItemInLocalStorage(key, item) {
            localStorage.setItem(key, JSON.stringify(item));
        }

        function storeItem(key, item) {
            key = "monsta-setting-" + key;
            if (localStorageSupported())
                storeItemInLocalStorage(key, item);
            else
                inMemoryStorage[key] = item;
        }

        function getItem(key) {
            key = "monsta-setting-" + key;
            if (localStorageSupported())
                return getItemFromLocalStorage(key);

            return inMemoryStorage[key];
        }

        function getConfigurationItem(key) {
            if(getItem(key) == undefined)
                return applicationSettings[key];

            return getItem(key);
        }

        function getApplicationSettings() {
            return configurationFactory.getSystemConfiguration().then(function (systemVars) {
                applicationSettings = systemVars.applicationSettings;
            }, requestFailure);
        }

        function setConfigurationItem(key, item) {
            var keyChanged = getItem(key) != item;

            storeItem(key, item);

            if(keyChanged)
                $rootScope.$broadcast('configuration:key-changed', key, item);
        }

        function requestFailure(response) {
            return $q.reject(response);
        }

        return factory;
    }
}());

