(function () {
    angular.module('MonstaFTP').controller('ModalTransferController', ModalTransferController);

    ModalTransferController.$inject = ['uploadFactory', '$rootScope', '$scope', 'jQuery', '$timeout',
        '$filter'];

    function ModalTransferController(uploadFactory, $rootScope, $scope, jQuery, $timeout, $filter) {
        var modalId = '#modal-transfers', vm = this;

        vm.updateUploads = updateUploads;
        vm.uploadFinished = uploadFinished;
        vm.show = show;
        vm.hide = hide;
        vm.abortItem = abortItem;
        vm.abortAll = abortAll
        vm.fsFilter = $filter('file_size');

        vm.uploads = [];
        vm.itemToAbort = null;

        $scope.$on('upload:start', this.updateUploads);
        $scope.$on('upload:error', this.updateUploads);
        $scope.$on('upload:progress', this.updateUploads);
        $scope.$on('upload:load', this.uploadFinished);
        $scope.$on('upload:add', this.show);

        function updateUploads() {
            vm.uploads = uploadFactory.getUploads();
            if (vm.uploads.length == 0)
                vm.hide();
            else {
                $timeout(function() {
                    $scope.$apply();
                });
            }
        }

        function uploadFinished() {
            vm.updateUploads();
            $rootScope.$broadcast('change-directory'); // refresh directory
        }

        function show() {
            vm.updateUploads();
            jQuery(modalId).modal('show');
        }

        function hide() {
            jQuery(modalId).modal('hide');
        }

        function abortItem(item) {
            uploadFactory.abortItem(item);
            vm.updateUploads();
        }

        function abortAll() {
            uploadFactory.abortAll();
        }
    }
}());