(function () {
    angular.module('MonstaFTP').factory('licenseFactory', licenseFactory);

    licenseFactory.$inject = ['connectionFactory', '$rootScope'];

    function licenseFactory(connectionFactory, $rootScope) {
        return {
            isNullLicense: true,
            email: null,
            version: null,
            expiryDate: null,
            purchaseDate: null,
            getLicense: function () {
                var _this = this;
                connectionFactory.getLicense().then(function (response) {
                    if (responseIsUnsuccessful(response)) {
                        _this.handleGetFailure.call(_this, response);
                        return;
                    }

                    _this.handleGetSuccess.call(_this, response);
                }, function (response) {
                    _this.handleGetFailure.call(_this, response);
                });
            },
            handleGetSuccess: function (response) {
                var licenseData = response.data.data;
                if (licenseData == null)
                    this.isNullLicense = true;
                else {
                    this.email = licenseData.email;
                    this.version = licenseData.version;
                    this.expiryDate = licenseData.expiryDate * 1000;
                    this.purchaseDate = licenseData.purchaseDate * 1000;
                    this.isNullLicense = false;
                }
                $rootScope.$broadcast('license-loaded');
            }, handleGetFailure: function (response) {
                var action = 'license reading';
                $rootScope.$broadcast('modal-error:show', parseErrorResponse(response, action), null, {action: action});
            }, isLicensed: function () {
                if (this.isNullLicense)
                    return false;

                return !this.isLicenseExpired();
            }, isLicenseExpired: function () {
                if (this.isNullLicense)
                    return false;

                if (this.expiryDate == null)
                    return false;

                return Date.now() > this.expiryDate;
            }
        };
    }
}());