function parentPath(inputPath){
    if(inputPath.length <= 1)
        return '/';

    var hasLeadingSlash = inputPath.substr(0, 1) == '/';

    while(inputPath.length && inputPath.substr(inputPath.length - 1, 1) == '/')
        inputPath = inputPath.substr(0, inputPath.length - 1);

    var normalizedPath = [];

    var splitPath = inputPath.split('/');

    for(var pathIndex = 0; pathIndex < splitPath.length - 1; ++pathIndex) {
        var pathComponent = splitPath[pathIndex];

        if (pathComponent.length == 0)
            continue;

        normalizedPath.push(pathComponent);
    }

    if (normalizedPath.length == 0)
        return '/';

    return (hasLeadingSlash ? '/' : '') + normalizedPath.join('/');
}