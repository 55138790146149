function normalizeFileSize(fileSize) {
    if (typeof(fileSize) != 'number')
        return '';

    var units = 'B', scaledSize = fileSize, sizeSteps = [
        [1099511627776, 'TB'],
        [1073741824, 'GB'],
        [1048576, 'MB'],
        [1024, 'KB']
    ];

    for (var i = 0; i < sizeSteps.length; ++i) {
        if (fileSize >= sizeSteps[i][0]) {
            scaledSize = fileSize / sizeSteps[i][0];
            scaledSize = scaledSize.toFixed(1);
            units = sizeSteps[i][1];
            break;
        }
    }

    if (units == 'KB')
        scaledSize = Math.round(scaledSize);

    return scaledSize + units;
}