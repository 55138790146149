(function() {
    angular.module('MonstaFTP').controller('ModalAddonsController', ModalAddonsController);

    ModalAddonsController.$inject = ['jQuery', 'licenseFactory', '$scope', '$filter'];

    function ModalAddonsController(jQuery, licenseFactory, $scope, $filter) {
        var modalId = '#modal-addons', vm = this;

        vm.show = show;

        $scope.$on('modal-addons:show', function () {
            vm.show();
        });

        function show() {
            vm.formattedExpiryDate = $filter('date')(licenseFactory.expiryDate, "d MMMM, yyyy");
            vm.licenseExpired = licenseFactory.isLicenseExpired();
            vm.isLicensed = licenseFactory.isLicensed();
            jQuery(modalId).modal('show');
        }
    }
}());