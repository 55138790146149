(function () {
    angular.module('MonstaFTP').factory('historyFactory', historyFactory);

    historyFactory.$inject = ['$rootScope'];

    function historyFactory($rootScope) {
        var HISTORY_CHANGED_EVENT_NAME = 'history-changed';
        var ensureTrailingSlash = function (path) {
            return path + (path.substr(path.length - 1) != '/' ? '/' : '');
        };

        var factory = {
            _fullHistory: [],
            _historyIndex: -1,
            getFullHistory: function () {
                return this._fullHistory;
            },
            getFullHistoryCount: function () {
                return this._fullHistory.length;
            },
            addEntry: function (path) {
                path = ensureTrailingSlash(path);

                if (this._historyIndex != this._fullHistory.length - 1)
                    this._fullHistory.splice(this._historyIndex + 1);

                this._fullHistory.push(path);
                ++this._historyIndex;
                $rootScope.$broadcast(HISTORY_CHANGED_EVENT_NAME);
            }, removeEntry: function (path) {
                path = ensureTrailingSlash(path);
                var changed = false;

                for(var i = this._fullHistory.length - 1; i >= 0; --i) {
                    var historyItem = this._fullHistory[i];
                    if (historyItem.length < path.length)
                        continue;

                    if (historyItem.substr(0, path.length) == path) {// this will take care of sub folders
                        this._fullHistory.splice(i, 1);
                        changed = true;
                    }
                }

                if(changed)
                    $rootScope.$broadcast(HISTORY_CHANGED_EVENT_NAME);
            },
            getFullHistoryItem: function (index) {
                return this._fullHistory[index];
            },
            getHistoryIndex: function () {
                return this._historyIndex;
            },
            setHistoryIndex: function (index) {
                this._historyIndex = index;
                $rootScope.$broadcast(HISTORY_CHANGED_EVENT_NAME);
                return this._fullHistory[index];
            },
            hasPreviousHistoryItem: function () {
                return this._historyIndex > 0;
            },
            hasNextHistoryItem: function () {
                return (this._historyIndex > -1) && this._historyIndex < (this._fullHistory.length - 1);
            },
            navigateBack: function () {
                if (!this.hasPreviousHistoryItem())
                    return;

                return this.setHistoryIndex(this.getHistoryIndex() - 1);
            },
            navigateForward: function () {
                if (!this.hasNextHistoryItem())
                    return;

                return this.setHistoryIndex(this.getHistoryIndex() + 1);
            },
            getUniqueHistory: function (historySize, currentDirectory) {
                if (currentDirectory != undefined)
                    currentDirectory = ensureTrailingSlash(currentDirectory);

                if (this.getFullHistoryCount() == 0)
                    return [];

                var uniqueHistory = [];
                for (var fullHistoryIndex = this.getFullHistoryCount() - 1; fullHistoryIndex >= 0; --fullHistoryIndex) {
                    var historyPath = this.getFullHistoryItem(fullHistoryIndex);
                    if (historyPath == currentDirectory)
                        continue;

                    if (uniqueHistory.indexOf(historyPath) == -1)
                        uniqueHistory.push(historyPath);

                    if (uniqueHistory.length == historySize)
                        break;
                }

                return uniqueHistory;
            },
            clearHistory: function () {
                this._fullHistory = [];
                this._historyIndex = -1;
                $rootScope.$broadcast(HISTORY_CHANGED_EVENT_NAME);
            }
        };

        return factory;
    }
}());