(function () {
    angular.module('MonstaFTP').factory('authenticationFactory', authenticationFactory);

    function authenticationFactory() {
        var configurationKey = 'monsta-configuration',
            connectionTypeKey = 'monsta-connectionType',
            initialDirectoryKey = 'monsta-initialDirectory',
            rememberLoginKey = 'monsta-rememberLogin',
            isAuthenticatedKey = 'monsta-isAuthenticated',
            hasServerSavedAuthenticationKey = 'monsta-hasServerSavedAuthentication';

        return {
            isAuthenticated: false,
            rememberLogin: false,
            configuration: null,
            connectionType: null,
            initialDirectory: null,
            hasServerSavedAuthentication: false,
            _localStorageSupported: null,
            getConfigurationAsJSON: function () {
                return JSON.stringify(this.configuration);
            },
            setConfigurationFromJSON: function (jsonConfig) {
                this.configuration = jsonConfig == null ? null : JSON.parse(jsonConfig);
            },
            localStorageSupported: function () {
                if (this._localStorageSupported == null) {
                    var testKey = 'test', storage = window.localStorage;
                    try {
                        storage.setItem(testKey, '1');
                        storage.removeItem(testKey);
                        this._localStorageSupported = true;
                    } catch (error) {
                        this._localStorageSupported = false;
                    }
                }
                return this._localStorageSupported;
            },
            postLogin: function () {
                this.isAuthenticated = true;
                this.saveSettings();
            },
            loadSettings: function () {
                if (!this.localStorageSupported())
                    return;

                this.loadMetaConfiguration();

                if (this.rememberLogin)
                    this.loadConfiguration();
            },
            saveSettings: function () {
                if (!this.localStorageSupported())
                    return;

                this.saveMetaConfiguration();

                if (this.rememberLogin)
                    this.saveConfiguration();
                else
                    this.clearConfiguration();
            },
            clearSettings: function () {
                if (!this.localStorageSupported())
                    return;

                if (!this.rememberLogin)
                    this.clearConfiguration();
            },
            logout: function () {
                this.isAuthenticated = false;
                this.initialDirectory = null;
                this.saveSettings();
                this.clearSettings();  // looks weird, but we want to save all, then clear out ones we don't want
                this.configuration = null;
                // if config has been saved to localStorage, this will be repopulated,  otherwise it clears out the form
            },
            loadConfiguration: function () {
                if (!this.localStorageSupported())
                    return;

                this.setConfigurationFromJSON(localStorage.getItem(configurationKey));
                this.connectionType = localStorage.getItem(connectionTypeKey);
                this.initialDirectory = localStorage.getItem(initialDirectoryKey);
            },
            saveConfiguration: function () {
                if (!this.localStorageSupported())
                    return;

                localStorage.setItem(configurationKey, this.getConfigurationAsJSON());
                localStorage.setItem(connectionTypeKey, this.connectionType);
                localStorage.setItem(initialDirectoryKey, this.initialDirectory);
            },
            clearConfiguration: function () {
                localStorage.removeItem(configurationKey);
                localStorage.removeItem(connectionTypeKey);
                localStorage.removeItem(initialDirectoryKey);
            },
            loadMetaConfiguration: function () {
                if (!this.localStorageSupported())
                    return;

                this.rememberLogin = localStorage.getItem(rememberLoginKey) === 'true';
                this.isAuthenticated = localStorage.getItem(isAuthenticatedKey) === 'true';
                this.hasServerSavedAuthentication = localStorage.getItem(hasServerSavedAuthenticationKey) === 'true';
            },
            saveMetaConfiguration: function () {
                if (!this.localStorageSupported())
                    return;

                localStorage.setItem(isAuthenticatedKey, this.isAuthenticated == true ? 'true' : 'false');
                localStorage.setItem(rememberLoginKey, this.rememberLogin == true ? 'true' : 'false');
                localStorage.setItem(hasServerSavedAuthenticationKey,
                    this.hasServerSavedAuthentication == true ? 'true' : 'false');
            },
            hasStoredAuthenticationDetails: function () {
                return !isEmpty(this.connectionType) && !isEmpty(this.configuration);
            },
            getActiveConfiguration: function () {
                var activeConfiguration = this.configuration[this.connectionType];
                if (typeof(activeConfiguration.port) != 'undefined' && activeConfiguration.port != null)
                    activeConfiguration.port = parseInt(activeConfiguration.port);
                return activeConfiguration;
            }
        };
    }
}());