(function () {
    angular.module('MonstaFTP').controller('HeaderController', HeaderController);

    HeaderController.$inject = ['$scope', '$rootScope', 'historyFactory'];

        function HeaderController($scope, $rootScope, historyFactory) {
        $scope.canGoBack = false;
        $scope.canGoForward = false;

        var updateScopeFromHistoryFactory = function () {
            $scope.canGoBack = historyFactory.hasPreviousHistoryItem();
            $scope.canGoForward = historyFactory.hasNextHistoryItem();
        };

        $scope.$on('history-changed', function () {
            updateScopeFromHistoryFactory();
        });

        $scope.$on('directory-changed', function () {
            updateScopeFromHistoryFactory();
        });

        $scope.navigateBack = function () {
            if (!$scope.canGoBack)
                return;

            var newDir = historyFactory.navigateBack();

            if (newDir)
                $rootScope.$broadcast('change-directory:on-history', newDir);
        };

        $scope.navigateForward = function () {
            if (!$scope.canGoForward)
                return;

            var newDir = historyFactory.navigateForward();

            if (newDir)
                $rootScope.$broadcast('change-directory:on-history', newDir);
        };

        $scope.refresh = function () {
            $rootScope.$broadcast('change-directory');
        }
    }
}());
