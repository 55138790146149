angular.module('MonstaFTP').filter('transfer_rate', function () {
    return function (upload) {
        if (upload.stats == null)
            return '-';

        var tr = upload.stats.calculateTransferRate();

        var fileSize = normalizeFileSize(tr);
        return fileSize == '' ? '-' : fileSize + '/s';
    };
});