(function () {
    angular.module('MonstaFTP').factory('uploadFactory', uploadFactory);

    uploadFactory.$inject = ['connectionFactory', '$rootScope', '$translate'];

    function uploadFactory(connectionFactory, $rootScope, $translate) {
        var EXTRACT_PROGRESS_STEPS = 8;

        return {
            _uploads: [],
            _activeUploadCount: 0,
            addUpload: function (name, remotePath, fileObject, size, isArchive) {
                if(!EXTRACT_UPLOAD)
                    isArchive = false;

                if (size > MAX_UPLOAD_BYTES)
                    return false;

                this._uploads.push({
                    name: name,
                    remotePath: remotePath,
                    file: fileObject,
                    request: null,
                    stats: new FileTransferStats(size),
                    hasError: false,
                    isArchive: isArchive,
                    archiveExtractMax: 0,
                    archiveExtractCurrent: -1
                });
                $rootScope.$broadcast('upload:add');
                if (this._activeUploadCount < MAX_CONCURRENT_UPLOADS)
                    this.startUploadOfItemAtIndex(this._uploads.length - 1);

                return true;
            },
            startNextItem: function () {
                if (this._activeUploadCount >= MAX_CONCURRENT_UPLOADS)
                    return;

                for (var itemIndex = 0; itemIndex < this._uploads.length; ++itemIndex) {
                    if (this._uploads[itemIndex].stats.hasBeenStarted())
                        continue;

                    this.startUploadOfItemAtIndex(itemIndex);
                    break;
                }
            },
            getUploads: function () {
                return this._uploads;
            },
            getUploadItem: function (itemIndex) {
                return this._uploads[itemIndex];
            },
            progressItem: function (uploadItem, transferredBytes) {
                uploadItem.stats.updateTransferAmount(transferredBytes);
                $rootScope.$broadcast('upload:progress');
            },
            getUploadRequestBody: function (remotePath, isArchive) {
                var requestBody = connectionFactory.getRequestBody();
                requestBody.actionName = isArchive ? UPLOAD_ARCHIVE_ACTION : UPLOAD_ACTION;

                requestBody.context = {
                    remotePath: remotePath
                };

                return requestBody;
            },
            encodeRequestBody: function (requestBody) {
                var jsonRequestBody = JSON.stringify(requestBody);

                return b64EncodeUnicode(jsonRequestBody);
            },
            getXHR: function () {
                return new XMLHttpRequest();
            },
            startXHR: function (request, requestBody, file) {
                ++this._activeUploadCount;
                request.open('POST', UPLOAD_PATH);
                request.setRequestHeader("X-Monsta", this.encodeRequestBody(requestBody));
                request.send(file);
                $rootScope.$broadcast('upload:start');
            },
            startUploadOfItemAtIndex: function (itemIndex) {
                var fileRequestDescription = this._uploads[itemIndex];
                var request = this.getXHR();
                fileRequestDescription.request = request;
                var _this = this;

                var requestBody = this.getUploadRequestBody(fileRequestDescription.remotePath,
                    fileRequestDescription.isArchive);

                request.upload.addEventListener("progress", function (e) {
                    if (request.readyState == XMLHttpRequest.OPENED)
                        _this.progressItem(fileRequestDescription, e.lengthComputable ? e.loaded : null);
                }, false);

                request.upload.addEventListener('load', function () {
                    fileRequestDescription.stats.completedBytes = fileRequestDescription.stats.totalBytes;
                    $rootScope.$broadcast('upload:progress');
                }, false);

                request.onreadystatechange = function () {
                    if (request.readyState === XMLHttpRequest.DONE) {
                        if (request.status == 200)
                            _this.completeItem(fileRequestDescription, request.responseText, false);
                        else if (request.status != 0) // is zero on abort
                            _this.setItemError(fileRequestDescription);
                    }
                };
                this.startXHR(request, requestBody, fileRequestDescription.file);
                fileRequestDescription.stats.wasStarted();
            },
            progressExtract: function (fileKey, uploadItem, fileCount, fileOffset) {
                var _this = this;
                connectionFactory.extractArchive(fileKey, fileOffset, EXTRACT_PROGRESS_STEPS).then(function () {
                    uploadItem.archiveExtractCurrent = Math.min(fileOffset + EXTRACT_PROGRESS_STEPS, fileCount);

                    if((fileOffset + EXTRACT_PROGRESS_STEPS) > (fileCount + 1)) {
                        _this.completeItem(uploadItem, null, true);
                    } else {
                        _this.progressExtract(fileKey, uploadItem, fileCount, fileOffset + EXTRACT_PROGRESS_STEPS);
                    }
                }, function (response) {
                    showResponseError(response, "extract archive", $rootScope, $translate);
                    _this.completeItem(uploadItem, null, true);
                });
            },
            completeItem: function (uploadItem, responseText, isPostExtract) {
                var _this = this;
                if(uploadItem.isArchive && !isPostExtract) {
                    var responseData = JSON.parse(responseText);
                    uploadItem.archiveExtractCurrent = 0;
                    uploadItem.archiveExtractMax = responseData.fileCount;
                    this.progressExtract(responseData.fileKey, uploadItem, responseData.fileCount, 0);
                } else {
                    --this._activeUploadCount;
                    uploadItem.request = null;
                    uploadItem.stats.complete();
                    this.removeItem(uploadItem);

                    setTimeout(function () {
                        _this.broadcastComplete.call(_this);
                    }, 0);
                }
            }, broadcastComplete: function () {
                $rootScope.$broadcast('upload:load');
                this.startNextItem();
            },
            abortItem: function (uploadItem) {
                --this._activeUploadCount;
                if (uploadItem.request != null) {
                    uploadItem.request.abort();
                    uploadItem.request = null;
                }

                this.removeItem(uploadItem);
                $rootScope.$broadcast('upload:abort');
                this.startNextItem();
            },
            removeItem: function (uploadItem) {
                this._uploadIterator(function (_itemIndex, _item) {
                    if (uploadItem.remotePath == _item.remotePath) {
                        if (_item.request != null)
                            return false;

                        this._uploads.splice(_itemIndex, 1);
                        return false;
                    }
                })
            },
            setItemError: function (uploadItem) {
                uploadItem.hasError = true;
                if (uploadItem.request != null) {
                    uploadItem.request = null;
                    $rootScope.$broadcast('upload:error');
                }
            },
            _uploadIterator: function (callback) {
                for (var itemIndex = 0; itemIndex < this._uploads.length; ++itemIndex) {
                    if (callback.call(this, itemIndex, this._uploads[itemIndex]) === false)
                        break;
                }
            },
            abortAll: function () {
                for (var itemIndex = 0; itemIndex < this._uploads.length; ++itemIndex) {
                    var uploadItem = this._uploads[itemIndex];

                    if (uploadItem.request != null) {
                        uploadItem.request.abort();
                        uploadItem.request = null;
                    }
                }
                this._uploads = [];
                $rootScope.$broadcast('upload:abort');
            }
        };
    }
}());