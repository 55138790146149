(function () {
    angular.module('MonstaFTP').controller('BreadcrumbController', BreadcrumbController);

    BreadcrumbController.$inject = ['$scope', '$rootScope', 'jQuery'];

    function BreadcrumbController($scope, $rootScope, jQuery) {
        var vm = this;

        vm.pathComponents = [];
        vm.hasLeadingSlash = false;
        vm.renderBreadcrumbs = renderBreadcrumbs;

        this.setPath = setPath;
        this.changeDirectoryToItem = changeDirectoryToItem;

        $scope.$on('directory-changed', directoryChanged);

        jQuery(window).resize(function () {
            vm.renderBreadcrumbs();
        });

        function linkClick(ev) {
            ev.preventDefault();
            vm.changeDirectoryToItem(jQuery(this).data('index'));
            return false;
        }

        function makeBreadcrumbItem(linkText, index) {
            var $span = jQuery('<li class="breadcrumb-display"></li>');
            var $link = jQuery('<a href="#" data-index="' + index + '"></a>').text(linkText + ' ');
            $link.click(linkClick);
            $span.append($link);
            return $span;
        }

        function renderBreadcrumbs() {
            var RIGHT_COMPONENT_BUFFER = 15, RIGHT_FULL_BUFFER = 120;
            // TODO: cache these elements so they aren't looked up every time
            var $breadcrumbContainer = jQuery("#breadcrumb-ol"), $homeLink = jQuery("#breadcrumb__home_link"),
                historyButton = jQuery('#history > button'), windowWidth = jQuery(window).width(),
                maxWidth = windowWidth - historyButton.width() - RIGHT_FULL_BUFFER, totalWidth = 0;
            $breadcrumbContainer.find('.breadcrumb-display').remove();

            var lastIndex = vm.pathComponents.length - 1, $rightSpan = null;

            for (var i = lastIndex; i >= 0; --i) {
                var component = vm.pathComponents[i];

                var $span = makeBreadcrumbItem(component, i + 1);

                if ($rightSpan === null)
                    $rightSpan = $span;

                $homeLink.after($span);

                totalWidth += $span.outerWidth();

                if (totalWidth > maxWidth) {
                    if (i != lastIndex) {
                        // only remove it if this isn't the first try
                        $span.remove();
                    }

                    if (lastIndex != 0) {
                        var $initialSpan = makeBreadcrumbItem("…", i + 1);
                        $homeLink.after($initialSpan);
                    }
                    break;
                }
            }

            if (totalWidth > maxWidth) {
                var charactersRemoved = 1;

                while ($rightSpan.offset().left + $rightSpan.outerWidth() + RIGHT_COMPONENT_BUFFER > windowWidth) {
                    ++charactersRemoved;
                    var lastComponent = vm.pathComponents[lastIndex], lastComponentLength = lastComponent.length;
                    var trimmedName = lastComponent.substr(0, lastComponentLength - charactersRemoved);
                    trimmedName += "…";
                    $rightSpan.text(trimmedName);
                    if (lastComponentLength - charactersRemoved == 1)
                        break; // this is safety to prevent infinite loops
                }
            }
        }

        function setPath(path) {
            if (typeof(path) != 'string' || path.length == 0) {
                vm.pathComponents = [];
                vm.hasLeadingSlash = false;
                return;
            }

            vm.hasLeadingSlash = path.substr(0, 1) == '/';

            if (path == '/')
                vm.pathComponents = [];
            else {
                vm.pathComponents = path.split('/');
                if (vm.pathComponents[0] == '')
                    vm.pathComponents.splice(0, 1);

                if (vm.pathComponents[vm.pathComponents.length - 1] == '')
                    vm.pathComponents.splice(vm.pathComponents.length - 1, 1);
            }

            vm.renderBreadcrumbs();
        }

        function changeDirectoryToItem(itemIndex) {
            var joinedPath = '';
            if (itemIndex != 0)
                joinedPath = vm.pathComponents.slice(0, itemIndex).join('/');

            var newPath = (vm.hasLeadingSlash ? '/' : '') + joinedPath;
            $rootScope.$broadcast('change-directory', newPath);
        }

        function directoryChanged(ev, path) {
            vm.setPath(path);
        }
    }
}());