function nameJoin(names) {
    switch (names.length) {
        case 0:
            return '';
        case 1:
            return names[0];
        default:
            var retVal = '';
            for (var i = 0; i < names.length - 1; ++i) {
                retVal += names[i];
                if (i < names.length - 2)
                    retVal += ', ';
            }
            retVal += ' and ' + names[names.length - 1];
            return retVal;
    }
}