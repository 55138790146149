(function () {
    angular.module('MonstaFTP').factory('selectedItemsFactory', selectedItemsFactory);

    selectedItemsFactory.$inject = ['$rootScope'];

    function selectedItemsFactory($rootScope) {
        var selectedItems = [];
        var initialSelectedIndex = 0;
        var dragStartIndex = 0;
        var metaDragStartIndex = 0;
        var metaSelectedItems;

        var factory = {
            getSelectedItems: getSelectedItems,
            standardClickAtIndex: standardClickAtIndex,
            shiftClickAtIndex: shiftClickAtIndex,
            metaClickAtIndex: metaClickAtIndex,
            clearSelection: clearSelection,
            startDraggingAtIndex: startDraggingAtIndex,
            draggedToIndex: draggedToIndex,
            startMetaDraggingAtIndex: startMetaDraggingAtIndex,
            metaDraggedToIndex: metaDraggedToIndex,
            selectItem: selectItem
        };

        function generateRange(start, stop) {
            if (start == stop)
                return [start];

            var delta = 1;

            if (start > stop)
                delta = -1;

            var range = [start];

            do {
                start += delta;
                range.push(start);
            } while(start != stop);

            return range;
        }

        function getSelectedItems() {
            return selectedItems;
        }

        function standardClickAtIndex(index) {
            if(selectedItems.length == 1 && selectedItems[0] == index) {
                selectedItems = [];
                broadcastSelectionChange();
                return;
            }

            if(selectedItems.indexOf(index) == -1 || selectedItems.length > 1) {
                // this is just for speed, we could do the below all the time but will be a little fast to skip it
                // unless it's actually necessary
                selectedItems = [index];
                initialSelectedIndex = index;
                broadcastSelectionChange();
            }
        }

        function shiftClickAtIndex(index) {
            if(selectedItems.length != 1 || selectedItems.indexOf(index) == -1) {
                selectedItems = generateRange(initialSelectedIndex, index);
                broadcastSelectionChange();
            }
        }

        function metaClickAtIndex(index) {
            var indexOfIndex = selectedItems.indexOf(index);

            if(indexOfIndex == -1)
                selectedItems.push(index);
            else
                selectedItems.splice(indexOfIndex, 1);
            broadcastSelectionChange();
        }

        function clearSelection() {
            selectedItems = [];
            broadcastSelectionChange();
        }

        function startDraggingAtIndex(index) {
            clearSelection();
            dragStartIndex = index;
            selectedItems = [index];
            broadcastSelectionChange();
        }

        function draggedToIndex(index) {
            selectedItems = generateRange(dragStartIndex, index);
            broadcastSelectionChange();
        }

        function startMetaDraggingAtIndex(index){
            metaDragStartIndex = index;
            metaSelectedItems = angular.copy(selectedItems);
        }

        function metaDraggedToIndex(index) {
            var newSelectedItems = angular.copy(metaSelectedItems),
                lowerBound = Math.min(index, metaDragStartIndex),
                upperBound = Math.max(index, metaDragStartIndex);

            for(; lowerBound <= upperBound; ++lowerBound){
                var indexOfIndex = metaSelectedItems.indexOf(lowerBound);

                if(indexOfIndex == -1)
                    newSelectedItems.push(lowerBound);
                else
                    newSelectedItems.splice(newSelectedItems.indexOf(lowerBound), 1);
            }

            selectedItems = newSelectedItems;
            broadcastSelectionChange();
        }

        function selectItem(index) {
            selectedItems = [index];
            broadcastSelectionChange();
        }

        function broadcastSelectionChange() {
            $rootScope.$broadcast('selected-items-changed');
        }

        return factory;
    }
}());


