angular.module('MonstaFTP').filter('sort_description', function () {
    return function (sortName) {
        // these are localized so must be uppercase
        switch (sortName) {
            case 'modified':
                return 'CHANGED';
            default:
                return sortName.toUpperCase();
        }
    };
});
