(function () {
    angular.module('MonstaFTP').filter('file_last_modified', filesLastModified);

    filesLastModified.$inject = ['dateFilter'];

    function filesLastModified(dateFilter) {
        function filter(input) {
            if (typeof(input) != 'number')
                return '';

            var inputDate = new Date(input * 1000);
            var currentDate = new Date();
            var format;

            if (inputDate.getDate() == currentDate.getDate() &&
                inputDate.getMonth() == currentDate.getMonth() &&
                inputDate.getFullYear() == currentDate.getFullYear())
                format = 'shortTime';
            else
                format = 'mediumDate';

            return dateFilter(inputDate, format)
        }

        return filter;
    }
}());