function showResponseError(response, action, $rootScope, $translate) {
    if (response.data && response.data.localizedErrors) {
        var expectedTranslations = response.data.localizedErrors.length, translatedErrors = [];

        function translateErrorDone(translatedError) {
            translatedErrors.push(translatedError);

            if (translatedErrors.length == expectedTranslations)
                $rootScope.$broadcast('modal-error:show', translatedErrors.join(' '));
        }

        for (var i = 0; i < expectedTranslations; ++i) {
            if (typeof(response.data.localizedErrors[i].context) == "undefined"
                || response.data.localizedErrors[i].context == null || true) {
                if (window.console && window.console.error) {
                    console.error(response.data);
                }
            }

            $translate(response.data.localizedErrors[i].errorName, response.data.localizedErrors[i].context).then(
                translateErrorDone, translateErrorDone);
        }
    } else
        $rootScope.$broadcast('modal-error:show', parseErrorResponse(response, action), null, {action: action});
}