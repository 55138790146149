(function () {
    angular.module('MonstaFTP').filter('human_time_since', humanTimeSince);
    function humanTimeSince() {
        function timestampToFormattedDate(timeStamp, dmyFormat) {
            var d = new Date(timeStamp * 1000);
            if (dmyFormat === true)
                return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();

            return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
        }

        return function (input, dmyFormat) {
            if (typeof(input) != 'number')
                return '';

            var scaledTimeDelta, units,
                timeDiff = Date.now() / 1000 - input,
                timeSteps = [
                    [3540, 60, 'minute'], // 59 minutes
                    [82800, 3600, 'hour'], // 23 hours
                    [2592000, 86400, 'day'] // 30 days
                ];

            if (timeDiff < 0 || timeDiff > timeSteps[timeSteps.length - 1][0])
                return timestampToFormattedDate(input, dmyFormat);
            else if (timeDiff <= 60)
                return 'now';

            for (var i = 0; i < timeSteps.length; ++i) {
                if (timeDiff > timeSteps[i][0])
                    continue;

                scaledTimeDelta = Math.round(timeDiff / timeSteps[i][1]);
                units = timeSteps[i][2];
                break;
            }

            if (scaledTimeDelta != 1)
                units += 's';

            return scaledTimeDelta + ' ' + units + ' ago';
        }
    }
}());