(function () {
    angular.module('MonstaFTP').factory('uiOperationFactory', uiOperationFactory);

    uiOperationFactory.$inject = ['$rootScope'];

    function uiOperationFactory($rootScope) {
        return {
            cutSource: null,
            copySource: null,
            currentDirectory: "",
            setCutSource: function (newCutSource) {
                this.cutSource = newCutSource;
                this.copySource = null;

                if (newCutSource != null)
                    $rootScope.$broadcast('paste-source:set');
            },
            setCopySource: function (newCopySource) {
                this.copySource = newCopySource;
                this.cutSource = null;

                if (newCopySource != null)
                    $rootScope.$broadcast('paste-source:set');
            },
            pasteComplete: function () {
                if (this.cutSource != null)
                    $rootScope.$broadcast('paste-source:cleared');
                this.cutSource = null;
            },
            clearCutAndCopySource: function () {
                this.copySource = null;
                this.cutSource = null;
                $rootScope.$broadcast('paste-source:cleared');
            },
            isCutOrCopySource: function (path) {
                return path == this.copySource || path == this.cutSource;
            },
            joinNameToCurrentDirectory: function (name) {
                return pathJoin(this.currentDirectory, name);
            }
        };
    }
}());