(function () {
    angular.module('MonstaFTP').controller('ModalConfirmController', ['$scope', 'jQuery', ModalConfirmController]);

    function ModalConfirmController($scope, jQuery) {
        var modalConfirmId = '#modal-confirm', vm = this;

        vm.message = '';
        vm.okCallback = null;
        vm.cancelCallback = null;

        vm.show = show;
        vm.ok = okHandler;
        vm.cancel = cancelHandler;

        $scope.$on('modal-confirm:show', function (ev, message, okCallback, cancelCallback) {
            vm.message = message;
            vm.okCallback = (typeof okCallback == 'undefined') ? null : okCallback;
            vm.cancelCallback = (typeof cancelCallback == 'undefined') ? null : cancelCallback;
            vm.show();
        });

        function okHandler() {
            jQuery(modalConfirmId).modal('hide');
            if (vm.okCallback != null) {
                vm.okCallback();
                vm.okCallback = null;
            }
        }

        function cancelHandler() {
            jQuery(modalConfirmId).modal('hide');
            if (vm.cancelCallback != null) {
                vm.cancelCallback();
                vm.cancelCallback = null;
            }
        }

        function show() {
            jQuery(modalConfirmId).modal('show');
        }
    }
}());