(function () {
    angular.module('MonstaFTP').controller('ModalErrorController', ModalErrorController);

    ModalErrorController.$inject = ['$scope', 'jQuery', '$translate'];

    function ModalErrorController($scope, jQuery, $translate) {
        var vm = this, modalErrorId = '#modal-error';
        vm.message = '';
        vm.show = show;
        vm.hide = hide;
        vm.dismissCallback = dismissCallback;

        $scope.$on('modal-error:show', function (ev, message, dismissCallback, context) {
            $translate(message, context).then(function(translatedMessage){
                vm.message = translatedMessage;
            }, function () {
                vm.message = message;
            });

            vm.dismissCallback = dismissCallback;
            jQuery(modalErrorId).modal('show');
        });

        jQuery(modalErrorId).on('shown.bs.modal', function () {
            $scope.$apply();
        });

        function show() {
            jQuery(modalErrorId).modal('show');
        }

        function hide() {
            jQuery(modalErrorId).modal('hide');
            if (vm.dismissCallback)
                vm.dismissCallback();
        }

        function dismissCallback() {
            // empty
        }
    }

}());
