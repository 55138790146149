(function () {
    angular.module('MonstaFTP').controller('HistoryController', HistoryController);

    HistoryController.$inject = ['$scope', 'historyFactory', '$rootScope', 'uiOperationFactory'];

    function HistoryController($scope, historyFactory, $rootScope, uiOperationFactory) {
        $scope.history = [];

        $scope.$on('directory-changed', function () {
            updateHistory();
        });

        $scope.$on('history-changed', function () {
            updateHistory();
        });

        $scope.historyClick = function (path) {
            $rootScope.$broadcast('change-directory', path);
        };

        $scope.$on('items-deleted', function (ev, itemPaths) {
            itemPaths.map(function (path) {
                historyFactory.removeEntry(path);
            });
        });

        function updateHistory() {
            $scope.history = historyFactory.getUniqueHistory(HISTORY_MAX, uiOperationFactory.currentDirectory);
        }
    }
}());