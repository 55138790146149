(function(){
    angular.module('MonstaFTP').controller('ModalSettingsController', ModalSettingsController);

    ModalSettingsController.$inject = ['jQuery', '$scope', 'configurationFactory', '$rootScope', '$translate',
        'localConfigurationFactory'];

    function ModalSettingsController(jQuery, $scope, configurationFactory, $rootScope, $translate,
                                     localConfigurationFactory) {
        var modalId = '#modal-settings', vm = this, applicationSettingsKeys = [], previousShowDotFiles;
        vm.applicationSettings = {};
        vm.show = show;
        vm.saveSettings = saveSettings;
        vm.debug = DEBUG;
        vm.systemShowDotFiles = false;

        configurationFactory.getSystemConfiguration().then(systemVarLoadSuccess, systemVarLoadFailure);

        $scope.$on('modal-settings:show', function () {
            localConfigurationFactory.getApplicationSettings().then(function(){
                for (var i = 0; i < applicationSettingsKeys.length; ++i) {
                    var key = applicationSettingsKeys[i];
                    vm.applicationSettings[key] = localConfigurationFactory.getConfigurationItem(key);
                }
                vm.show();
            }, systemVarLoadFailure);
        });

        function show() {
            previousShowDotFiles = vm.applicationSettings.showDotFiles;
            jQuery(modalId).modal('show');
        }

        function hide() {
            jQuery(modalId).modal('hide');
        }

        function systemVarLoadSuccess(vars) {
            vm.systemShowDotFiles = vars.applicationSettings.showDotFiles;
            applicationSettingsKeys = Object.keys(vars.applicationSettings);
        }

        function systemVarLoadFailure(response) {
            showResponseError(response, "system var load", $rootScope, $translate);
        }
        
        function saveSettings() {
            for(var key in vm.applicationSettings){
                if (vm.applicationSettings.hasOwnProperty(key))
                    localConfigurationFactory.setConfigurationItem(key, vm.applicationSettings[key]);
            }

            if (vm.applicationSettings.showDotFiles != previousShowDotFiles)
                $rootScope.$broadcast('change-directory');

            hide();
        }

        $rootScope.$on('configuration:key-changed', function (ev, key, value) {
            if(key == 'language') {
                $translate.use(value);
            }
        });
    }
}());