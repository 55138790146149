angular.module('MonstaFTP').filter('icon', function () {
    return function (item) {
        if (item.isDirectory)
            return 'fa-folder';

        if (item.isLink)
            return 'fa-long-arrow-right';

        var iconName = '';

        switch (extractFileExtension(item.name)) {
            case 'doc':
            case 'docx':
                iconName = 'word';
                break;
            case 'xlr':
            case 'xls':
            case 'xlsx':
                iconName = 'excel';
                break;
            case 'ppt':
            case 'pps':
            case 'pptx':
                iconName = 'powerpoint';
                break;
            case 'pdf':
                iconName = 'pdf';
                break;
            case 'txt':
            case 'rtf':
            case 'text':
                iconName = 'text';
                break;
            case 'bmp':
            case 'gif':
            case 'jpg':
            case 'png':
            case 'psd':
            case 'tif':
            case 'ai':
            case 'eps':
            case 'svg':
            case 'ps':
            case 'jpeg':
                iconName = 'image';
                break;
            case 'avi':
            case 'flv':
            case 'm4v':
            case 'mov':
            case 'mp4':
            case 'mkv':
            case 'mpg':
            case 'wmv':
                iconName = 'video';
                break;
            case 'wav':
            case 'mp3':
            case 'wma':
            case 'm4a':
            case 'm4p':
            case 'mpa':
            case 'flac':
            case 'aif':
            case 'aiff':
                iconName = 'audio';
                break;
            case 'tar':
            case 'zip':
            case 'tgz':
            case 'gz':
            case 'gzip':
            case 'rar':
                iconName = 'archive';
                break;
            case 'htm':
            case 'html':
            case 'php':
            case 'asp':
            case 'aspx':
            case 'js':
            case 'css':
            case 'xhtml':
            case 'cfm':
            case 'pl':
            case 'py':
            case 'c':
            case 'cpp':
            case 'rb':
            case 'java':
            case 'xml':
                iconName = 'code';
                break;
            default:
                break;
        }

        return 'fa-file' + (iconName == '' ? '' : '-') + iconName + '-o';
    }
});