(function () {
    angular.module('MonstaFTP').controller('ModalPermissionsController', ModalPermissionsController);

    ModalPermissionsController.$inject = ['$scope', '$rootScope', 'connectionFactory', 'jQuery','permissionsFactory',
        '$translate'];

    function ModalPermissionsController($scope, $rootScope, connectionFactory, jQuery, permissionsFactory, $translate) {
        var modalPermissionsEditorId = '#modal-chmod', vm = this;
        $scope.filePaths = null;
        $scope.permissions = null;
        $scope.formattedPermissions = null;
        $scope.invalidRange = false;
        $scope.saving = false;

        vm.show = show;
        vm.hide = hide;
        vm.validateFormattedPermission = validateFormattedPermission;
        vm.formattedPermissionsChange = formattedPermissionsChange;
        vm.setPermissions = setPermissions;
        vm.zeroPadLeft = zeroPadLeft;
        vm.setFormattedPermissions = setFormattedPermissions;
        vm.permissionsChange = permissionsChange;
        vm.permissionSaveError = permissionSaveError;
        vm.permissionSaveSuccess = permissionSaveSuccess;
        vm.initiatePermissionsSave = initiatePermissionsSave;

        $scope.$on('modal-permissions:show', function (ev, filePaths, numericPermissions) {
            $scope.filePaths = filePaths;
            vm.setPermissions(numericPermissions);
            vm.show();
        });

        $scope.$watch('permissions', vm.permissionsChange, true);

        $scope.$watch('formattedPermissions', vm.formattedPermissionsChange);

        $scope.manualFocus = function () {
            $scope.invalidRange = false;
        };

        $scope.okClick = function () {
            if ($scope.invalidRange)
                return;

            vm.initiatePermissionsSave();
        };

        $scope.cancelClick = function () {
            vm.hide();
        };

        function show() {
            jQuery(modalPermissionsEditorId).modal('show');
            $scope.invalidRange = false;
        }

        function hide() {
            $scope.filePaths = null;
            $scope.invalidRange = false;
            jQuery(modalPermissionsEditorId).modal('hide');
        }

        function setPermissions(numericPermissions) {
            $scope.permissions = permissionsFactory.numericToObject(numericPermissions);
        }

        function zeroPadLeft(input) {
            while (input.length < 3)
                input = '0' + input;

            return input;
        }

        function setFormattedPermissions(numericPermissions) {
            $scope.formattedPermissions = vm.zeroPadLeft(numericPermissions.toString(8));
        }

        function permissionsChange() {
            if ($scope.permissions != null)
                vm.setFormattedPermissions(permissionsFactory.objectToNumeric($scope.permissions));
        }

        function validateFormattedPermission(formattedPermission) {
            var numericPermissions = parseInt(formattedPermission, 8);
            if (isNaN(numericPermissions) || numericPermissions < 0 || numericPermissions > 511) {
                numericPermissions = 0;
                $scope.invalidRange = true;
            }
            return numericPermissions;
        }

        function formattedPermissionsChange() {
            vm.setPermissions(vm.validateFormattedPermission($scope.formattedPermissions));
        }

        function permissionSaveError(error, context) {
            $scope.saving = false;
            $translate(['PERMISSIONS_FAILURE_PRECEDING_MESSAGE', error], context).then(function (translations) {
                $rootScope.$broadcast('modal-error:show',
                    translations['PERMISSIONS_FAILURE_PRECEDING_MESSAGE'] + ' ' + translations[error]);
            });
        }

        function permissionSaveSuccess() {
            $scope.saving = false;
            $rootScope.$broadcast('change-directory');
            vm.hide();
        }

        function initiatePermissionsSave() {
            var filesSaved = 0, mode = permissionsFactory.objectToNumeric($scope.permissions);

            var checkCompleted = function () {
                ++filesSaved;

                if (filesSaved == $scope.filePaths.length)
                    vm.permissionSaveSuccess();
            };

            $scope.saving = true;

            $scope.filePaths.map(function (path) {
                connectionFactory.changePermissions(path, mode).then(function () {
                    checkCompleted();
                }, function (response) {
                    var action = "permission save";
                    vm.permissionSaveError(parseErrorResponse(response, action), {action: action});
                });
            });
        }
    }
}());