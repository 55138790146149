angular.module('MonstaFTP').filter('transfer_percent', function () {
    return function (upload) {
        if (!upload.hasError && upload.request == null && !upload.stats.hasBeenStarted()) {
            return 0;
        }

        if (upload.stats == null)
            return 0;

        if(upload.archiveExtractMax != 0 && upload.archiveExtractCurrent != -1)
            return upload.archiveExtractCurrent / upload.archiveExtractMax * 100;

        return upload.stats.getTransferPercent();
    };
});