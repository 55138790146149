(function(){
    angular.module('MonstaFTP').controller('ModalPropertiesController', ModalPropertiesController);

    ModalPropertiesController.$inject = ['jQuery', '$scope'];

    function ModalPropertiesController(jQuery, $scope) {
        var vm = this, modalPropertiesId = "#modal-properties";
        vm.item = null;

        vm.hide = hide;
        vm.show = show;

        $scope.$on('modal-properties:show', function (ev, item) {
           vm.show(item);
        });

        function hide() {
            jQuery(modalPropertiesId).modal('hide');
        }

        function show(item){
            vm.item = item;
            jQuery(modalPropertiesId).modal('show');
        }
    }
}());