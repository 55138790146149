(function () {
    angular.module('MonstaFTP').controller('FooterController', FooterController);

    FooterController.$inject = ['$scope', '$rootScope', 'uiOperationFactory', 'connectionFactory',
        'authenticationFactory', 'uploadUIFactory', '$translate', 'selectedItemsFactory', 'configurationFactory'];

    function FooterController($scope, $rootScope, uiOperationFactory, connectionFactory,
                              authenticationFactory, uploadUIFactory, $translate, selectedItemsFactory,
                              configurationFactory) {
        var vm = this, actionsRequiringSelection = ['chmod', 'cut', 'copy', 'download'];

        vm.isArchiveUpload = false;

        $scope.selectedItemsCount = 0;
        $scope.hasPasteSource = false;
        $scope.maxUploadBytes = MAX_UPLOAD_BYTES;
        $scope.currentUsername = null;
        $scope.currentHost = null;
        $scope.currentVersion = 0;
        $scope.newVersionAvailable = false;
        $scope.editorActive = false;

        vm.handleUpload = handleUpload;
        vm.handleUploadFolder = handleUploadFolder;
        vm.allowAction = allowAction;
        vm.performRemoteFetch = performRemoteFetch;
        vm.remoteFetchCallback = remoteFetchCallback;
        vm.initiateRemoteFetch = initiateRemoteFetch;
        vm.onEditorHide = onEditorHide;
        vm.showEditor = showEditor;
        vm.validateArchiveUpload = validateArchiveUpload;

        $scope.buttonClick = function ($event) {
            var buttonName = ($event.currentTarget.getAttribute('data-name'));
            if (buttonName == 'upload-file') {
                vm.isArchiveUpload = false;
                vm.handleUpload();
                return;
            } else if (buttonName == 'upload-folder') {
                vm.handleUploadFolder();
                return;
            } else if (buttonName == 'upload-archive') {
                vm.isArchiveUpload = true;
                vm.handleUpload();
                return;
            } else if (buttonName == 'fetch-file') {
                vm.initiateRemoteFetch();
                return;
            } else if (buttonName == 'show-editor') {
                if (!vm.allowAction(buttonName))
                    return;

                vm.showEditor();
                return;
            }

            if (!vm.allowAction(buttonName))
                return;

            $rootScope.$broadcast('footer-button-click', buttonName);
        };

        var uploadSingleInput = document.getElementById('upload-placeholder'),
            uploadFolderInput = document.getElementById('upload-folder-placeholder');

        if (uploadSingleInput) {
            uploadSingleInput.addEventListener('change', fileChangeHandler);
            uploadFolderInput.addEventListener('change', fileChangeHandler);
        }

        $scope.$on('selected-items-changed', function () {
            $scope.selectedItemsCount = selectedItemsFactory.getSelectedItems().length;
        });

        $scope.$on('paste-source:set', function () {
            $scope.hasPasteSource = true;
        });

        $scope.$on('paste-source:cleared', function () {
            $scope.hasPasteSource = false;
        });

        $scope.$on('login', function () {
            var currentConfig = authenticationFactory.getActiveConfiguration();
            $scope.currentUsername = currentConfig.username || null;
            $scope.currentHost = currentConfig.host || null;
        });

        $scope.$on('logout', function () {
            $scope.currentUsername = null;
            $scope.currentHost = null;
        });

        $scope.$on('file-editor:hide', function (ev, activeFileCount) {
            vm.onEditorHide(activeFileCount);
        });

        configurationFactory.getSystemConfiguration().then(function (config) {
            MAX_UPLOAD_BYTES = config.maxFileUpload;
            $scope.maxUploadBytes = MAX_UPLOAD_BYTES;
            $scope.currentVersion = config.version;

            if ($scope.currentVersion && window.MONSTA_LATEST_VERSION)
                $scope.newVersionAvailable = versionIsLessThan($scope.currentVersion, window.MONSTA_LATEST_VERSION);
        }, function (response) {

        });

        function handleUpload() {
            uploadSingleInput.value = null;
            uploadSingleInput.click();
        }

        function fileChangeHandler() {
            if (!this.files || !this.files.length)
                return;

            var items = this.items;

            if(vm.isArchiveUpload && !validateArchiveUpload(this.files))
                return;

            if (items != undefined)
                uploadUIFactory.handleItemsBasedUpload(items, vm.isArchiveUpload);
            else
                uploadUIFactory.handleFilesBasedUpload(this.files, vm.isArchiveUpload);
        }

        function showModalError(errorMessage) {
            $rootScope.$broadcast("modal-error:show", errorMessage);
        }

        function handleUploadFolder() {
            var userAgent = window.navigator.userAgent;

            if (!/chrome/i.test(userAgent)) {
                $translate('FOLDER_UPLOAD_CHROME_ONLY_MESSAGE').then(showModalError, showModalError);
                return;
            }

            uploadFolderInput.value = null;
            uploadFolderInput.click();
        }

        function allowAction(actionName) {
            if (actionName == 'show-editor')
                return $scope.editorActive;

            if (actionName == 'paste')
                return $scope.hasPasteSource;

            if (actionsRequiringSelection.indexOf(actionName) == -1)
                return true;

            return $scope.selectedItemsCount != 0;
        }

        function performRemoteFetch(url) {
            $translate('FETCHING_ACTIVITY_STATUS').then(function (translatedBusyMessage) {
                $rootScope.$broadcast("modal-prompt:set-busy", translatedBusyMessage);
            });

            connectionFactory.fetchRemoteFile(url, uiOperationFactory.currentDirectory).then(
                function (response) {
                    if (responseIsUnsuccessful(response)) {
                        showResponseError(response, 'remote file fetch of ' + url, $rootScope, $translate);
                        return;
                    }
                    $rootScope.$broadcast('change-directory');
                    $rootScope.$broadcast('modal-prompt:hide');
                }, function (response) {
                    showResponseError(response, 'remote file fetch of ' + url, $rootScope, $translate);
                    $rootScope.$broadcast("modal-prompt:clear-busy");
                }
            );
        }

        function setModalPromptError(error) {
            $rootScope.$broadcast('modal-prompt:set-error', error);
        }

        function remoteFetchCallback(final) {
            $rootScope.$broadcast('modal-prompt:clear-error');

            if (!basicURLValidate(final)) {
                $translate('URL_INVALID_MESSAGE').then(setModalPromptError, setModalPromptError);
                return;
            }

            var url = final.replace(/^\s\s*/, '');

            vm.performRemoteFetch.call(vm, url);
        }

        function initiateRemoteFetch() {
            $translate(['FETCH_FILE_PROMPT_TITLE', 'FETCH_FILE_URL_PLACEHOLDER']).then(function (translations) {
                $rootScope.$broadcast('modal-prompt:show', translations.FETCH_FILE_PROMPT_TITLE, null,
                    translations.FETCH_FILE_URL_PLACEHOLDER, vm.remoteFetchCallback);
            });
        }

        function onEditorHide(activeFileCount) {
            $scope.editorActive = activeFileCount != 0;
        }

        function showEditor() {
            $rootScope.$broadcast('file-editor:show');
        }

        function validateArchiveUpload(files) {
            var errorMessage = null;
            if(files.length != 1)
                errorMessage = "MULTIPLE_FILE_ARCHIVE_ERROR";
            else if (extractFileExtension(files[0].name) != 'zip')
                errorMessage = "INVALID_TYPE_ARCHIVE_ERROR";

            if (errorMessage == null)
                return true;

            $rootScope.$broadcast('modal-error:show', errorMessage);
            return false;
        }
    }
}());