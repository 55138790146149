(function () {
    angular.module('MonstaFTP').controller('SpinnerController', SpinnerController);

    SpinnerController.$inject = ['$scope'];

    function SpinnerController($scope) {
        $scope.spinnerVisible = false;

        $scope.$on('request-count-change', function (ev, reqCount) {
            $scope.spinnerVisible = reqCount != 0;
        });
    }
}());
