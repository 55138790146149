(function () {
    angular.module('MonstaFTP').factory('directorySortingFactory', directorySortingFactory);

    function directorySortingFactory() {
        var compareByDirectoryFlag = function (a, b) {
            if (a.isDirectory == b.isDirectory)
                return 0;

            return a.isDirectory ? -1 : 1;
        };

        var compareByName = function (a, b) {
            var directoryCompare = compareByDirectoryFlag(a, b);
            if (directoryCompare != 0)
                return directoryCompare;

            if (a.name.toLowerCase() == b.name.toLowerCase())
                return 0;

            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        };

        var compareByModificationDate = function (a, b) {
            var directoryCompare = compareByDirectoryFlag(a, b);
            if (directoryCompare != 0)
                return directoryCompare;

            if (a.modificationDate != b.modificationDate)
                return a.modificationDate - b.modificationDate;

            return compareByName(a, b);
        };

        var compareBySize = function (a, b) {
            var directoryCompare = compareByDirectoryFlag(a, b);
            if (directoryCompare != 0)
                return directoryCompare;

            if (a.size != b.size)
                return a.size - b.size;

            return compareByName(a, b);
        };

        var compareByType = function (a, b) {
            var directoryCompare = compareByDirectoryFlag(a, b);
            if (directoryCompare != 0)
                return directoryCompare;

            var aExt = extractFileExtension(a.name), bExt = extractFileExtension(b.name);
            if (aExt != bExt)
                return aExt > bExt ? 1 : -1;

            return compareByName(a, b);
        };

        return {
            _handleReverse: function (sortedList, reverse) {
                if (reverse)
                    sortedList.reverse();

                return sortedList;
            },
            sortByName: function (unsortedList, reverse) {
                return this._handleReverse(unsortedList.sort(compareByName), reverse);
            },
            sortByModificationDate: function (unsortedList, reverse) {
                return this._handleReverse(unsortedList.sort(compareByModificationDate), reverse);
            },
            sortBySize: function (unsortedList, reverse) {
                return this._handleReverse(unsortedList.sort(compareBySize), reverse);
            },
            sortByType: function (unsortedList, reverse) {
                return this._handleReverse(unsortedList.sort(compareByType), reverse);
            }
        };
    }
}());