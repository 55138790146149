(function () {
    angular.module('MonstaFTP').factory('permissionsFactory', permissionsFactory);

    function permissionsFactory() {
        return {
            objectToNumeric: function (permissions) {
                return (permissions.ownerRead ? 0x100 : 0) + (permissions.ownerWrite ? 0x80 : 0) +
                    (permissions.ownerExecute ? 0x40 : 0) + (permissions.groupRead ? 0x20 : 0) +
                    (permissions.groupWrite ? 0x10 : 0) + (permissions.groupExecute ? 0x8 : 0) +
                    (permissions.otherRead ? 0x4 : 0) + (permissions.otherWrite ? 0x2 : 0) +
                    (permissions.otherExecute ? 0x1 : 0);
            },
            numericToObject: function (numericPermission) {
                return {
                    ownerRead: (numericPermission & 0x100) != 0,
                    ownerWrite: (numericPermission & 0x80) != 0,
                    ownerExecute: (numericPermission & 0x40) != 0,
                    groupRead: (numericPermission & 0x20) != 0,
                    groupWrite: (numericPermission & 0x10) != 0,
                    groupExecute: (numericPermission & 0x8) != 0,
                    otherRead: (numericPermission & 0x4) != 0,
                    otherWrite: (numericPermission & 0x2) != 0,
                    otherExecute: (numericPermission & 0x1) != 0
                };
            }
        }
    }
}());

