(function () {
    angular.module('MonstaFTP').controller('ModalPromptController', ModalPromptController);

    ModalPromptController.$inject = ['$scope', 'jQuery', '$translate'];

    function ModalPromptController($scope, jQuery, $translate) {
        var modalPromptId = '#modal-prompt', vm = this;

        this.setVars = function (title, initial, placeHolder) {
            $translate(title).then(function (translatedTitle) {
                $scope.title = translatedTitle;
            }, function () {
                $scope.title = title;
            });

            $translate(placeHolder).then(function (translatedPlaceholder) {
                $scope.placeHolder = translatedPlaceholder;
            }, function () {
                $scope.placeHolder = placeHolder;
            });

            $scope.initial = initial;
            $scope.final = initial;
            $scope.errorSet = false;
            $scope.errorMessage = '';
            $scope.isBusy = false;
            $scope.busyMessage = null;
            this.updateDismissMessage();
        };

        this.updateDismissMessage = function () {
            $translate($scope.busyMessage || 'DISMISS_OK_ACTION').then(function (translatedDismissMessage) {
                $scope.dismissMessage = translatedDismissMessage;
            }, function (dismissMessage) {
                $scope.dismissMessage = dismissMessage;
            });
        };

        jQuery(modalPromptId).on('shown.bs.modal', function () {
            jQuery(this).find('input[type=text]').focus();
        });

        this.setVars('', '', '');

        this.successCallback = function () {
            // empty
        };

        $scope.successClose = function () {
            vm.successCallback($scope.final, $scope.initial);
        };

        $scope.handlePromptKeypress = function ($event) {
            if ($event.which == 13)
                $scope.successClose();
        };

        this.show = function () {
            jQuery(modalPromptId).modal('show');
            vm.clearError();
            vm.clearBusy();
        };

        this.hide = function () {
            jQuery(modalPromptId).modal('hide');
        };

        this.clearError = function () {
            $scope.errorSet = false;
            $scope.errorMessage = '';
        };

        this.setBusy = function (busyMessage) {
            $scope.isBusy = true;
            $scope.busyMessage = busyMessage;
            this.updateDismissMessage();
        };

        this.clearBusy = function () {
            $scope.isBusy = false;
            $scope.busyMessage = null;
            this.updateDismissMessage();
        };

        $scope.$on('modal-prompt:show', function (ev, title, initial, placeHolder, successCallback) {
            vm.setVars(title, initial, placeHolder);
            vm.successCallback = successCallback;
            vm.show();
        });

        $scope.$on('modal-prompt:set-error', function (ev, errorMessage) {
            $scope.errorSet = true;

            $translate(errorMessage).then(function (translatedErrorMessage) {
                $scope.errorMessage = translatedErrorMessage;
            }, function () {
                $scope.errorMessage = errorMessage;
            });
        });

        $scope.$on('modal-prompt:clear-error', function () {
            vm.clearError();
        });

        $scope.$on('modal-prompt:hide', function () {
            vm.hide();
        });

        $scope.$on('modal-prompt:set-busy', function (ev, busyMessage) {
            vm.setBusy(busyMessage);
        });

        $scope.$on('modal-prompt:clear-busy', function () {
            vm.clearBusy();
        });
    }
}());