function parseErrorResponse(response, action) {
    if (!response.data || !response.data.errors) {
        var actionMsg = isEmpty(action) ? '' : ' during ' + action;

        if(response.status == 408 || response.status == -1)
            return "OPERATION_TIMEOUT";
        else
            return "An unknown error occurred" + actionMsg + ".";
    } else {
        return response.data.errors.join(' ');
    }
}