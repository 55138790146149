function extractFileExtension(fileName) {
    if (typeof (fileName) != 'string')
        return '';

    var nameComponents = fileName.split('.');

    if (nameComponents.length == 1 || (nameComponents.length == 2 && nameComponents[0] == ''))  // case 2 -> it starts with a .
        return '';

    return nameComponents[nameComponents.length - 1].toLowerCase();
}