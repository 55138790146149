(function(){
    angular.module('MonstaFTP').factory('rowMouseTrackerFactory', rowMouseTrackerFactory);

    rowMouseTrackerFactory.$inject = ['selectedItemsFactory'];

    function rowMouseTrackerFactory(selectedItemsFactory) {
        var factory = {}, mouseIsDown = false, mouseMovedWhileDown = false, mouseDownEvent,
            rowClickTargetElementNames = ['DIV', 'TR', 'TD'], rowDragStartIndex = null, isMetaDrag = false;

        factory.mouseDown = mouseDown;
        factory.mouseUp = mouseUp;
        factory.mouseMove = mouseMove;
        factory.mouseLeave = mouseLeave;

        factory.mouseClick = mouseClick;
        factory.mouseDrag = mouseDrag;
        factory.startDrag = startDrag;

        function mouseDown($event) {
            mouseIsDown = true;
            mouseDownEvent = $event;
            mouseMovedWhileDown = false;
        }

        function mouseUp(rowIndex) {
            if (mouseIsDown && !mouseMovedWhileDown) {
                factory.mouseClick(mouseDownEvent, rowIndex);
            }
            mouseIsDown = false;
            rowDragStartIndex = null;
        }

        function mouseMove($event, rowIndex) {
            if(mouseIsDown) {
                mouseMovedWhileDown = true;
                if(rowDragStartIndex == rowIndex)
                    return;

                if (rowDragStartIndex == null)
                    factory.startDrag($event, rowIndex);
                else
                    factory.mouseDrag(rowIndex);

                rowDragStartIndex = rowIndex;
            }
        }

        function mouseLeave($event) {
            if (rowClickTargetElementNames.indexOf($event.target.tagName) != -1)
                return true;

            rowDragStartIndex = null;
        }

        function mouseDrag(rowIndex) {
            if (isMetaDrag)
                selectedItemsFactory.metaDraggedToIndex(rowIndex);
            else
                selectedItemsFactory.draggedToIndex(rowIndex);
        }

        function mouseClick($event, itemIndex) {
            if (rowClickTargetElementNames.indexOf($event.target.tagName) == -1)
                return true;
            $event.preventDefault();
            if ($event.ctrlKey || $event.metaKey)
                selectedItemsFactory.metaClickAtIndex(itemIndex);
            else if ($event.shiftKey)
                selectedItemsFactory.shiftClickAtIndex(itemIndex);
            else
                selectedItemsFactory.standardClickAtIndex(itemIndex);
        }

        function startDrag($event, rowIndex) {
            if ($event.metaKey || $event.ctrlKey) {
                isMetaDrag = true;
                selectedItemsFactory.startMetaDraggingAtIndex(rowIndex);
            } else {
                isMetaDrag = false;
                selectedItemsFactory.startDraggingAtIndex(rowIndex);
            }
        }

        return factory;
    }
}());