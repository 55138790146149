function mapParseInt(val) {
    return parseInt(val);
}

function simpleCompare(a, b) {
    return a - b;
}

function betaVersionComponentCompare(component1, component2) {
    var component1IsBeta = component1.indexOf('b') != -1;
    var component2IsBeta = component2.indexOf('b') != -1;

    var splitComponent1 = component1.split('b').map(mapParseInt);
    var splitComponent2 = component2.split('b').map(mapParseInt);

    if (component1IsBeta && component2IsBeta) {
        var c1Compare = simpleCompare(splitComponent1[0], splitComponent2[0]);
        if (c1Compare != 0)
            return c1Compare;

        return simpleCompare(splitComponent1[1], splitComponent2[1]);
    } else if (component1IsBeta) {
        return splitComponent1[0] > splitComponent2[0] ? 1 : -1;
        // xby < x
    } else {
        return splitComponent2[0] > splitComponent1[0] ? 1 : -1;
    }
}

function versionComponentCompare(component1, component2) {
    if (component1.indexOf('b') != -1 || component2.indexOf('b') != -1)
        return betaVersionComponentCompare(component1, component2);

    return simpleCompare(parseInt(component1), parseInt(component2));
}

function versionIsLessThan(version1, version2) {
    var splitV1 = version1.split('.');

    if (splitV1.length == 2)
        splitV1.push('0');

    var splitV2 = version2.split('.');

    if (splitV2.length == 2)
        splitV2.push('0');

    for (var i = 0; i < 3; ++i) {
        var comparison = versionComponentCompare(splitV1[i], splitV2[i]);

        if (comparison != 0)
            return comparison < 0;
    }

    return false;
}